import { FocusStyleManager } from '@blueprintjs/core';
import axios from 'axios';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { After, ensureReady } from '~/afterjs';
import uiRoutes from '../server/ui-routes';

import configureStore from '~/reducers/configure-store';

// Set Axios default
if (window.__CSRF_TOKEN__) {
  axios.defaults.headers.common['X-CSRF-Token'] = window.__CSRF_TOKEN__;
}
axios.defaults.headers.common['cache-control'] = 'no-cache';
axios.defaults.headers.common.Accept = 'application/json';
// Whether or not cross-site Access-Control requests should be made using credentials
// axios.defaults.withCredentials = true;

// CSS should be loaded in specific orders
// tslint:disable ordered-imports
import 'normalize.css/normalize.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import 'animate.css/animate.css';
import './app.scss';

FocusStyleManager.onlyShowFocusOnTabs();

const store = configureStore(window.__PRELOADED_STATE__);

ensureReady(uiRoutes).then((data) =>
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <After data={data} routes={uiRoutes} />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  )
);

if (module.hot) {
  module.hot.accept();
}
