import NotFoundComponent from './NotFoundComponent';
import {
  AsyncRouteableComponent,
  AsyncRouteComponentType,
  AsyncRouteProps,
} from './types';

/** @private is the given object a Function? */
export const isFunction = (obj: any) => 'function' === typeof obj;

/** @private is the given object an Object? */
export const isObject = (obj: any) => obj !== null && typeof obj === 'object';

/** @private is the given object/value a promise? */
export const isPromise = (value: any): boolean =>
  isObject(value) && isFunction(value.then);

/** @private Guard cluase to narrow the AsyncRouteableComponent union type on getInitialProps */
export function isAsyncComponent(
  Component: AsyncRouteableComponent
): Component is AsyncRouteComponentType<any> {
  return (
    (Component as AsyncRouteComponentType<any>).getInitialProps !== undefined
  );
}

/** @private Guard cluase to narrow the AsyncRouteableComponent union type on load */
export function isLoadableComponent(
  Component: AsyncRouteableComponent
): Component is AsyncRouteComponentType<any> {
  return (Component as AsyncRouteComponentType<any>).load !== undefined;
}

/** @private is given routes have 404 page?  */
export function is404ComponentAvailable(
  routes: Array<AsyncRouteProps<any>>
): AsyncRouteProps<any> | false {
  return (
    // @ts-ignore
    routes.find((route) => ['**', '*', undefined].includes(route.path)) || false
  );
}

/** @private Returns 404Component from given routes if component was not avaliable returns default 404component */
export function get404Component(
  routes: Array<AsyncRouteProps<any>>
): AsyncRouteableComponent<any> {
  const match = is404ComponentAvailable(routes);
  return match ? match.component : NotFoundComponent;
}

/** @private Checks if 404Component is in routes, if it's not available add default 404 component */
export function getAllRoutes(
  routes: Array<AsyncRouteProps<any>>
): Array<AsyncRouteProps<any>> {
  return is404ComponentAvailable(routes)
    ? routes
    : [...routes, { component: NotFoundComponent }];
}
