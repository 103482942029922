import { asyncComponent } from '~/afterjs';

const ADMIN_ROOT = '/:city/admin';

const routes: UiRouteT[] = [
  {
    name: 'Login',
    path: `${ADMIN_ROOT}/login`,
    component: asyncComponent({
      loader: () => import('~/components/view-auth/login'),
    }),
    exact: true,
  },
  {
    name: 'QR code verification',
    path: `${ADMIN_ROOT}/verify-coupon/:couponId`,
    component: asyncComponent({
      loader: () => import('~/components/view-auth/verify-coupon'),
    }),
    exact: true,
  },
  {
    name: 'QR code scanner',
    path: `${ADMIN_ROOT}/scan-coupon`,
    component: asyncComponent({
      loader: () => import('~/components/view-auth/scan-coupon'),
    }),
    exact: true,
  },
  {
    name: 'Admin Landing',
    path: `${ADMIN_ROOT}/`,
    component: asyncComponent({
      loader: () => import('~/components/view-auth/home'),
    }),
    exact: true,
  },
];

export default routes;
