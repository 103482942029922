import qs from 'qs';

import { ApiSuccess, callApi } from './svc-util';

export default class AuthSvc {
  static login(city: string, username: string, password: string) {
    const config = {
      method: 'post',
      url: `/auth/${city}/login`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({ username, password }),
    };
    const success: ApiSuccess<UserT> = (response) => response.data;

    return callApi(config, success);
  }

  static logout(city: string) {
    const config = {
      method: 'get',
      url: `/auth/${city}/logout`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const success: ApiSuccess<void> = (response) => {
      return;
    };

    return callApi(config, success);
  }

  static getAuthenticatedUser(city: string) {
    const config = {
      method: 'get',
      url: `/auth/${city}/user`,
    };
    const success: ApiSuccess<UserT> = (response) => response.data;

    return callApi(config, success);
  }

  static changePassword(
    city: string,
    currentPassword: string,
    newPassword: string
  ) {
    const config = {
      method: 'post',
      url: `/auth/${city}/change-password`,
      data: {
        currentPassword,
        newPassword,
      },
    };
    const success: ApiSuccess<void> = (response) => response.data;

    return callApi(config, success);
  }
}
