import { asyncComponent } from '~/afterjs';

const routes: UiRouteT[] = [
  {
    name: 'Springboard',
    path: '/',
    component: asyncComponent({
      loader: () => import('~/components/view/springboard'),
    }),
    exact: true,
  },
  {
    name: 'Unsubscribe Email',
    path: '/:city/unsubscribe-email',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/unsubscribe-email'),
    }),
  },
  {
    name: 'Home',
    path: '/:city',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/home'),
    }),
  },
  {
    name: 'Promotions',
    path: '/:city/promotions',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/promotions'),
    }),
  },
  {
    name: 'Programs & Pricing',
    path: '/:city/programs-and-pricing',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/programs-and-pricing'),
    }),
  },
  {
    name: 'Map & Contact Information',
    path: '/:city/contact-us',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/contact-us'),
    }),
  },
  {
    name: 'New to Bingo?',
    path: '/:city/new-to-bingo',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/new-to-bingo'),
    }),
  },
  {
    name: 'Charity Involvement',
    path: '/:city/charity-info',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/charity-info'),
    }),
  },
  {
    name: 'Rules & Regulations',
    path: '/:city/rules-and-regulations',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/rules-and-regulations'),
    }),
  },
  {
    name: 'Food & Beverage',
    path: '/:city/food-and-beverage',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/food-and-beverage'),
    }),
  },
  {
    name: "Tap N' Play",
    path: '/:city/tap-n-play',
    exact: true,
    component: asyncComponent({
      loader: () => import('~/components/view/tap-n-play'),
    }),
  },
];

export default routes;
