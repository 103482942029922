import { asyncComponent } from '~/afterjs';

import adminRoutes from './admin-routes';
import normalRoutes from './normal-routes';

export interface UiRouteT {
  // Internally these will become:
  // <Route
  //    path={path}
  //    exact={exact}
  //    render={props => <component {...props} data={data} />}
  // />
  path?: string;
  exact?: boolean;
  component: React.ComponentType<any>;

  name?: string;
}

const routes: UiRouteT[] = [
  ...normalRoutes,
  ...adminRoutes,
  // Fallback route, when nothing matches
  {
    path: '*',
    component: asyncComponent({
      loader: () => import('~/components/view/not-found'),
    }),
  },
];

export default routes;
