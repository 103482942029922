import { TabId } from '@blueprintjs/core';
import { createActionCreator, createReducer } from 'deox';

interface State {
  readonly tabId?: TabId;
}

const initialState: State = {
  tabId: undefined,
};

const setTabId = createActionCreator(
  'ADMIN/SET_TAB_ID',
  (resolve) => (tabId: TabId) => resolve(tabId)
);

export const adminActions = {
  setTabId,
};

export default createReducer(initialState, (handleAction) => [
  handleAction(setTabId, (state, { payload }) => ({
    ...state,
    tabId: payload,
  })),
]);

interface SelectorT {
  admin: State;
}
export const adminSelectors = {
  getTabId: ({ admin: state }: SelectorT) => state.tabId,
};
